import React, { useState } from "react";
import db from "../../Firebase/firebase";
import { FaInstagramSquare, FaTwitter } from "react-icons/fa";

import "./home.css";
import Navbar from "../Navbar/";

import bgVid from "../../assets/website_background.mp4";

const Home = () => {
  const [email, setEmail] = useState("");

  const handleOnSubmit = (e) => {
    e.preventDefault();

    if (!email.includes("@")) {
      console.log("didn't included @");
      return;
    } else if (email.length <= 2) {
      console.log("length");
      return;
    }

    db.collection("emailsWaitlist").add({
      email,
    });

    setEmail("");
  };

  return (
    <div className="homeContainer">
      <Navbar />
      <div className="HomeNavContainer">
        <h3 className="HomeLogo">Gossyp</h3>
        <div className="HomeIcons">
          <FaTwitter className="HomeNavLogo" style={{ marginRight: "1rem" }} />
          <FaInstagramSquare className="HomeNavLogo" />
        </div>
      </div>
      <video className="video" autoPlay loop muted>
        {/* TODO put autoplay later */}
        <source src={bgVid} />
      </video>
      <div className="textContent">
        <h3 className="heading">
          Bring back all <br /> creators together
        </h3>
        <p className="homePara">
          A place where content creators <br /> relive thier loving moments with{" "}
          <br /> their friends and jam around.
        </p>
        <div className="newsletter">
          <svg
            className="letterIcon"
            viewBox="0 0 77 69"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M57.2272 0.375C62.3118 0.375 67.2031 2.38458 70.8014 5.99046C74.4035 9.58875 76.4168 14.4421 76.4168 19.5229V49.4771C76.4168 60.0558 67.8097 68.625 57.2272 68.625H19.7693C9.18679 68.625 0.583496 60.0558 0.583496 49.4771V19.5229C0.583496 8.94417 9.14887 0.375 19.7693 0.375H57.2272ZM63.2596 25.1721L63.5629 24.8687C64.4691 23.7691 64.4691 22.1766 63.5212 21.0771C62.9941 20.5121 62.2699 20.1671 61.5154 20.0912C60.7191 20.0495 59.9608 20.3187 59.3883 20.8496L42.2916 34.4996C40.0925 36.3233 36.9416 36.3233 34.7083 34.4996L17.6458 20.8496C16.4666 19.9775 14.8362 20.0912 13.8541 21.115C12.8304 22.1387 12.7166 23.7691 13.5849 24.9066L14.0816 25.3996L31.3337 38.86C33.4571 40.5283 36.0316 41.4383 38.7275 41.4383C41.4158 41.4383 44.0358 40.5283 46.1553 38.86L63.2596 25.1721Z"
              fill="white"
            />
          </svg>
          <form onSubmit={(e) => handleOnSubmit(e)} className="Homeform">
            <input
              className="homeInput"
              placeholder="Subscribe for launch update"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default Home;
