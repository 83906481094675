import React from "react";
import "./navbar.css";
import { FaInstagramSquare, FaTwitter } from "react-icons/fa";

const Navbar = () => {
  return (
    <div className="navContainer">
      <h3 className="logo">Gossyp</h3>
      <div className="icons">
        <a
          className="socialLinks"
          href="https://www.instagram.com/gossypapp/"
          target="_blank  noopener noreferrer  "
        >
          <FaInstagramSquare style={{ marginRight: "0.8rem" }} />
        </a>
        <a
          className="socialLinks"
          href="https://twitter.com/GossypApp"
          target="_blank noopener noreferrer "
        >
          <FaTwitter />
        </a>
      </div>
    </div>
  );
};

export default Navbar;
